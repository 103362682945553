<template>
  <div class="main-layout">
    <div class="main-layout__header">
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          router
      >
        <el-menu-item index="/">Билдер</el-menu-item>
        <el-menu-item index="/toggles" >Тогглы</el-menu-item>
        <el-menu-item index="/logs">Логи</el-menu-item>
        <div class="flex-grow" />
        <el-menu-item>
          <el-dropdown>
            <el-button circle type="primary">
              <template #icon>
                <el-icon size="14"><UserFilled/></el-icon>
              </template>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="user?.username">{{user.username}}</el-dropdown-item>
                <el-dropdown-item>
                  <el-switch
                      v-model="isDark"
                      :active-icon="Moon"
                      :inactive-icon="Sunny"
                      inline-prompt
                      style="--el-switch-off-color: var(--el-color-warning)"
                  />
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-checkbox v-model="isAlert" label="Уведомлять о завершении билда" size="large" />
                </el-dropdown-item>
                <el-dropdown-item divided @click="logout()">Выйти</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="main-layout__content">
      <slot/>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, onMounted, ref, watch} from "vue";
import router from "@/router";
import {Moon, Sunny, UserFilled} from "@element-plus/icons-vue";
import { useDark, } from '@vueuse/core'

const isDark = useDark({selector: 'html',})

const user = ref({
  username: null
})

const isAlert = ref(false)
watch(isAlert,()=>{
  localStorage.setItem('isAlert', isAlert.value.toString())
})
onMounted(()=>{
  isAlert.value = JSON.parse(localStorage.getItem('isAlert') || 'false')
})

const activeIndex = computed(()=>router.currentRoute.value.path)
watch(activeIndex,()=>{
  getMe(activeIndex.value)
})
const getMe = (index: string)=>{
  if(index !== '/'){
    let service = index
    fetch(`https://giant-administrator-14g8gg7nyh69.site${service}/users/me`,{
      credentials: 'include'
    })
        .then(response=>{
          response.json()
              .then(result=>{
                console.log(result)
                if(result.status !== 401 && result.status !== 403 && !result.detail) user.value.username = result.username
                else {
                  window.location.href = `https://giant-administrator-14g8gg7nyh69.site${service}/users/login`
                }
              })
        })
        .catch(error=>{
          console.error(error)
        })
  }
}
const logout = ()=>{
  router.push('/login')

  fetch(`https://giant-administrator-14g8gg7nyh69.site/builder/users/logout`,{
    credentials: 'include'
  }).then(response=>{
    console.log(response)
  }).catch(error=>{console.error(error)})
}
</script>


<style lang="scss">
@use "@/styles/assembly" as *;
@use "@/styles/variables" as *;
.main-layout{
  padding: 40px 15% 30px;
  overflow: scroll;
  min-height: calc(100% - 80px);
  @media #{$md-and-down} {
    padding: $md-padding;
  }
  .flex-grow {
    flex-grow: 1;
  }
  &__header{
    .el-dropdown i{
      margin-right: 0;
    }
  }
}
</style>